import React from "react";
import { footerImages } from "../../helpers/getImages";
import { Link } from "react-router-dom";

const Footer: React.FC<{}> = () => {
  const [imageLarge, imageMedium] = footerImages;

  const srcSet = `
    ${imageMedium.path} 1200w,
    ${imageLarge.path} 1900w,
  `;

  return (
    <footer
      id="about"
      className="text-gray-800 relative font-thin bg-['/assets/Footer.jpg'] bg-center 
      bg-no-repeat bg-cover bg-fixed py-16 pr-6 pl-6 lg:pl-52 flex flex-col justify-end min-h-screen h-screen snap-start snap-always font-default"
    >
      <div className="max-w-7xl mx-auto w-full text-right text-2xl flex flex-col mb-40 z-10">
        <span className="block">I am a photographer and digital artist</span>
        <span className="block">based in Berlin</span>
        <span className="block">
          check out my
          <a
            href="http://www.lisaschoger.art"
            className="inline-block font-defaultItalic hover:text-g10 ml-1"
          >
            digital montages
          </a>
        </span>
        <span>
          or say
          <a
            href="mailto:hello@lisaschoger.photo"
            className="inline-block font-defaultItalic hover:text-g10 mx-1"
          >
            hello
          </a>
        </span>
      </div>
      <img
        src={process.env.PUBLIC_URL + imageMedium.path}
        alt=""
        className="object-cover w-full h-full absolute top-0 right-0 left-0 bottom-0 -scale-x-100"
        loading="lazy"
        sizes="100vw"
        srcSet={srcSet}
      />
      <div className="absolute left-3 bottom-3 font-navigationMedium z-30 text-xs flex">
        <Link to="/imprint" className="mr-3">
          Imprint
        </Link>
        <Link to="/privacy">Privacy Policy</Link>
      </div>
    </footer>
  );
};

export default Footer;
