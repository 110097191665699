import { Pictures } from "../../helpers/getImages";

const SliderItem: React.FC<{
  image: {
    imageLarge: Pictures;
    imageMedium: Pictures;
  };
}> = ({ image }) => {
  const { imageMedium, imageLarge } = image;

  const srcSet = `
  ${imageMedium.path} 500w,
  ${imageLarge.path} 800w,
`;

  return (
    <div className="h-[500px] min-w-[410px] lg:min-w-[580px] lg:h-[700px] mx-3 shadow-lg shadow-gray-800 snap-center">
      <img
        src={process.env.PUBLIC_URL + image.imageMedium.path}
        alt=""
        className="object-cover w-full h-full"
        loading="lazy"
        sizes={`
        (max-width: 1024px) 100vw,
        (min-width: 1025px) 50vw
        `}
        srcSet={srcSet}
      />
    </div>
  );
};

export default SliderItem;
