import SliderItem from "./SliderItem";

const Slider: React.FC<{ images: any[] }> = ({ images }) => {
  return (
    <>
      {images.map((img, i) => (
        <SliderItem key={i} image={img} />
      ))}
    </>
  );
};

export default Slider;
