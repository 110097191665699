import { useEffect, useRef } from "react";
import {
  analogImages,
  Pictures,
  polaroidImages,
} from "../../helpers/getImages";
import Slider from "../Slider/Slider";

const Main = () => {
  const stickyContainer = useRef<Array<HTMLElement | null>>([]);

  const mapPictures = (pictures: Array<Pictures>) => {
    let newArray = [];

    for (let index = 0; index < pictures.length - 2; index +=2) {
      newArray.push({
        imageLarge: pictures[index],
        imageMedium: pictures[index + 1],
      });
    }

    return newArray;
  };

  const polaroids = mapPictures(polaroidImages);
  const analog = mapPictures(analogImages);

  const setContainerHeight = () => {
    let heights: Array<string> = ["auto", "auto"];

    if (window.innerWidth >= 1024) {
      heights = stickyContainer.current.map((container) => {
        return `${
          container!.firstElementChild!.scrollWidth - window.innerHeight
        }px`;
      });
    }

    heights.forEach((height, index) => {
      stickyContainer.current[index]!.setAttribute(
        "style",
        `height: ${height}`
      );
    });
  };

  const isElementInViewport = (el: HTMLElement) => {
    if (el) {
      const rect = el.getBoundingClientRect();

      return (
        rect.top <= 0 && rect.bottom > document.documentElement.clientHeight
      );
    } else {
      return false;
    }
  };

  const wheelHandler = (e: WheelEvent) => {
    const containerInViewPort = Array.from(stickyContainer.current)
      .filter((container) => isElementInViewport(container!))
      .map((container) => {
        return {
          stickyContainer: container,
          mainContainer: container?.firstElementChild,
        };
      })[0];

    if (!containerInViewPort || !containerInViewPort?.stickyContainer) {
      return;
    } else {
      let isPlaceHolderBelowTop =
        containerInViewPort.stickyContainer!.offsetTop <
        document.documentElement.scrollTop;
      let isPlaceHolderBelowBottom =
        containerInViewPort.stickyContainer!.offsetTop +
          containerInViewPort.stickyContainer!.offsetHeight >
        document.documentElement.scrollTop;
      let g_canScrollHorizontally =
        isPlaceHolderBelowTop && isPlaceHolderBelowBottom;

      if (g_canScrollHorizontally) {
        containerInViewPort.mainContainer!.scrollLeft += e.deltaY;
      }
    }
  };

  useEffect(() => {
    window.addEventListener("wheel", wheelHandler, { passive: true });
    window.addEventListener("resize", () => setContainerHeight());
    setContainerHeight();
  }, []);

  return (
    <main className="noscroll w-full overflow-x-hidden lg:overflow-x-visible">
      <div
        id="polaroids"
        className="noscroll flex relative"
        ref={(element) => (stickyContainer.current[0] = element)}
      >
        <div className="sticky top-0 overflow-x-auto lg:noscroll flex py-8 h-screen items-center pl-28 lg:pl-96 lg:overflow-x-hidden">
          <div className="absolute left-0 lg:left-20 top-2/4 -translate-y-2/4 p-8 text-white bg-g1 shadow-custom z-10 text-vertical">
            <h2 className="-scale-100 font-navigation text-4xl">Polaroids</h2>
          </div>
          <Slider images={polaroids} />
        </div>
      </div>
      <div
        id="analog"
        className="noscroll flex relative"
        ref={(element) => (stickyContainer.current[1] = element)}
      >
        <div className="sticky top-0 overflow-x-auto lg:noscroll flex py-8 h-screen items-center pl-28 lg:pl-96 lg:overflow-x-hidden">
          <div className="absolute left-0 lg:left-20 top-2/4 -translate-y-2/4 p-8 text-white bg-g1 shadow-custom z-10 text-vertical">
            <h2 className="-scale-100 font-navigation text-4xl">Analog</h2>
          </div>
          <Slider images={analog} />
        </div>
      </div>
    </main>
  );
};

export default Main;
