const importAll = (r: __WebpackModuleApi.RequireContext, path: string) => {
  return r.keys().map((fileName: string) => ({
    path: path + fileName.slice(2),
    name: fileName.slice(2),
  }));
};

export interface Pictures {
  path: string;
  name: string;
}

export const polaroidImages: Pictures[] = importAll(
  require.context("../../public/assets/polaroids", false, /\.(webp)$/, "lazy"),
  "/assets/polaroids/"
);

export const analogImages: Pictures[] = importAll(
  require.context("../../public/assets/analog", false, /\.(webp)$/, "lazy"),
  "/assets/analog/"
);

export const footerImages: Pictures[] = importAll(
  require.context("../../public/assets/footer", false, /\.(webp)$/, "lazy"),
  "/assets/footer/"
);

export const headerImages: Pictures[] = importAll(
  require.context("../../public/assets/header", false, /\.(webp)$/, "lazy"),
  "/assets/header/"
);
