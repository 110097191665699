import { Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import { lazy, Suspense } from "react";

const Imprint = lazy(() => import("./pages/imprint"));
const PrivacyPolicy = lazy(() => import("./pages/privacy"));
const renderLoader = () => <p>Loading</p>;

function App() {
  return (
    <Suspense fallback={renderLoader()}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
      </Routes>
    </Suspense>
  );
}

export default App;
