
import { useEffect } from "react";
import Footer from "../components/UI/Footer";
import Header from "../components/UI/Header";
import Main from "../components/UI/Main";
import Signature from "../components/UI/Signature";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="scroll-smooth noscroll">
      <Signature />
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default Home;
