import React from "react";

const Signature: React.FC<{}> = () => {
  return (
    <span className="font-navigationMedium text-2xl fixed top-5 left-5 z-30 text-g11">
      Lisa Schoger
    </span>
  );
};

export default Signature;
