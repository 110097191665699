import React, { useRef } from "react";
import { headerImages } from "../../helpers/getImages";

const Stage: React.FC<{}> = () => {
  const stageContainer = useRef<HTMLElement>(null);

  const [imageLarge, imageMedium] = headerImages;

  const srcSet = `
    ${imageMedium.path} 1200w,
    ${imageLarge.path} 1900w,
  `;

  return (
    <header
      id="intro"
      className="relative flex justify-end content-end items-end h-screen w-full overflow-hidden p-8 pb-28 md:p-8 snap-start snap-always"
      ref={stageContainer}
    >
      <img
        src={process.env.PUBLIC_URL + imageMedium.path}
        alt=""
        className="object-cover w-full h-full absolute top-0 right-0 left-0 bottom-0"
        loading="lazy"
        sizes="100vw"
        srcSet={srcSet}
      />
      <div className="w-full h-fit md:w-3/4 lg:w-1/2 overflow-y-auto noscroll z-10 lg:mb-10">
        <h2 className="text-4xl font-defaultItalic mb-3 text-black">
          deceleration
        </h2>
        <p className="text-lg my-2">
          the following collection opens the door to a slowed-down world where
          the experience and the uniqueness of the moment overweights the
          mass-production of reality.
        </p>
      </div>
    </header>
  );
};

export default Stage;
